// import js dependencies

import AjaxPost from "./ajax";
import Swipers from "./swiper";

//Get Ajax Responses
jQuery(($) => {
    const swipers = new Swipers({});
    swipers.init();

    const ajaxPost = new AjaxPost({});
    ajaxPost.init();

    $(".menu-toggle").click(function () {
        $(this)
            .toggleClass("open")
            .parent()
            .find("#menu-primary")
            .stop()
            .slideToggle(200);
        $("#menu-primary")
            .find("li.open")
            .removeClass("open")
            .find("ul")
            .stop()
            .slideUp(200);
    });

    $(".menu-item-has-children > a").click(function (e) {
        if (!$(this).parent().hasClass("open") && $(window).width() < 992) {
            e.preventDefault();
            $("#menu-primary")
                .find("li.open")
                .removeClass("open")
                .find("ul")
                .stop()
                .slideUp(200);
            $(this)
                .parent()
                .toggleClass("open")
                .find("ul")
                .stop()
                .slideToggle(200);
        }
    });

    $(".service-nav button").click(function () {
        if (!$(this).parent().hasClass("active")) {
            let index = $(this).parent().attr("data-index");
            let block = $(this).closest(".block-services");
            block.find(".service-nav .active").removeClass("active");
            $(this).parent().addClass("active");
            block.find(".service-nav").attr("data-active", index);

            
            block.find(".service.active").removeClass("active");
            block
                .find('.service[data-index="' + index + '"]')
                .addClass("active");
        }
    });

    $(".service > button").click(function () {

        var $this = $(this);

        if($(this).parent().hasClass("active")) {
            $this.parent().removeClass("active")
        }else{
            $this.parent().addClass("active")
        }
    });

    $(".block-faqs button").click(function () {
        if (!$(this).parent().hasClass("active")) {
            let index = $(this).parent().attr("data-index");
            let block = $(this).closest(".block-faqs");
            block.find("li.active").removeClass("active");
            $(this).parent().addClass("active");
            block.find(".faqs").attr("data-active", index);
        }
    });

    $("body").on("click", ".tessellateConsent", function (event) {
        event.preventDefault();
        $(".cs-info-sticky-button").click();
    });
});
