import Swiper from "swiper/bundle";
import "swiper/css/bundle";

const $ = jQuery;

export default class Swipers {
    constructor(options = null) {}

    init() {
        this.__swiper1();
    }

    __swiper1() {
        const $swiperBlocks1 = $(".swiper_block");

        let swiper;

        for (const swiperBlock of $swiperBlocks1) {
            const swiperBlockId = $(swiperBlock).attr("id");

            swiper = new Swiper("#" + swiperBlockId, {
                speed: 1000,
                slidesPerView: 1,
                autoplay: false,
                loop: false,
                effect: "fade",
                allowTouchMove: false,
                fadeEffect: {
                    crossFade: true,
                },
            });
        }

        // control click on swiper

        $("body").on("click", "#buildings .swiper-slide", function (event) {
            var target = event.target;

            var buildings = document.querySelectorAll(
                "#buildings .swiper-slide"
            );

            buildings.forEach(function (slide) {
                slide.classList.remove("clicked-slide");
            });

            $(this).addClass("clicked-slide");

            var dataslide = $(this).attr("data-slide");

            swiper.slideTo(dataslide);
        });

        const $swiperBlocks = $(".swiper_buildings");

        let swiper_buildings;

        for (const swiperBlock of $swiperBlocks) {
            const swiperBlockId = $(swiperBlock).attr("id");

            swiper_buildings = new Swiper("#" + swiperBlockId, {
                speed: 1000,
                slideToClickedSlide: true,
                autoplay: false,
                loop: false,
                on: {
                    activeIndexChange: function (index) {
                        swiper.slideTo(index.activeIndex);
                        // add clicked class to active slide
                        $("#buildings .swiper-slide").removeClass(
                            "clicked-slide"
                        );
                        var element = $(
                            '[data-slide="' + index.activeIndex + '"]'
                        );

                        $(element).addClass("clicked-slide");
                    },
                },
                breakpoints: {
                    0: {
                        slidesPerView: "auto",
                        centeredSlides: true,
                        allowTouchMove: true,
                    },
                    1240: {
                        slidesPerView: "auto",
                        centeredSlides: false,
                        allowTouchMove: false,
                    },
                },
            });
        }

        $("body").on("click", ".prevSector", function (event) {
            console.log("prev");
            swiper_buildings.slidePrev();
        });

        $("body").on("click", ".nextSector", function (event) {
            console.log("next");
            swiper_buildings.slideNext();
        });

        function handleResize() {
            if (window.innerWidth < 1280) {
                swiper.slideTo(0); // Move to the first slide
                swiper_buildings.slideTo(0); // Move to the first slide
                $("#buildings .swiper-slide").removeClass("clicked-slide");
                $("#buildings .swiper-slide:first").addClass("clicked-slide");
            }

            if (window.innerWidth > 1280) {
                swiper.slideTo(0); // Move to the first slide
                swiper_buildings.slideTo(0); // Move to the first slide
                $("#buildings .swiper-slide").removeClass("clicked-slide");
                $("#buildings .swiper-slide:first").addClass("clicked-slide");
            }
        }

        if ($(".swiper_block").length) {
            window.addEventListener("resize", handleResize);
        }
    }
}
